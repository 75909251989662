<template>
  <div class="discord">
    <app-header title="Discord Authorization Connect" :hide-back="true"/>
    <div class="layout layout--wrap layout--center">
      <div class="flex" style="max-width: 800px">
        <div class="tile mt">
          <progress-linear v-if="loading" :indeterminate="true"/>
          <div class="bodytext bodytext--larger">
            <h2 :class="{'text--success': successful}">{{statusText}}</h2>
            <p>{{statusMessage}}</p>
            <p>If you need support, don't hesitate to ask for help on our discord: <a href="https://discord.gg/5MMxacV" target="_blank">https://discord.gg/5MMxacV</a> at the channel <span class="text--teal">#en_website_support</span>.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '../../components/AppHeader';
import secured from "../../api/secured";
import { pageTitleMixin } from "../../mixins/pageTitle";
import { notificationsMixin } from "../../mixins/notifications";

export default {
  name: 'DiscordHandleAuthorizationConnect',
  components: {
    AppHeader
  },
  mixins: [pageTitleMixin, notificationsMixin],
  data: () => ({
    loading: false,
    successful: false,
    statusText: 'Processing...',
    statusMessage: 'Please wait',
  }),
  methods: {
  },
  created() {
    this.setPageTitle('Discord Authorization Connect');

    // ?error=access_denied&error_description=The+resource+owner+or+authorization+server+denied+the+request

    const { query } = this.$route;

    if (query.error) {
      this.showErrorNotifications(query.error);
    }
    if (query.error_description) {
      this.showErrorNotifications(query.error_description);
    }
    if (this.$route.query.code) {
      this.loading = true;
      secured.post('/identity/myself/connect-discord', {
        code: query.code,
        redirectUrl: 'https://hq.hyperion-corporation.de/discord-handle-connect'
      }).then((res) => {
        this.successful = true;
        this.statusText = 'Discord connected';
        this.statusMessage = 'You can now login with Discord.'
        this.loading = false;
      }).catch((error) => {
        this.successful = false;
        this.statusText = 'Discord not connected';
        this.statusMessage = 'Something went wrong and you maybe cannot login with discord.'
        this.loading = false;
        this.statusText = 'Failed';
        this.errorMessage = error;
        this.showErrorNotifications(error);
      });
    }
  }
}
</script>
